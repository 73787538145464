var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LiefengContent',{attrs:{"isBack":true},on:{"backClick":_vm.backClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("浏览记录")]},proxy:true},{key:"toolsbarLeft",fn:function(){return undefined},proxy:true},{key:"toolsbarRight",fn:function(){return [_c('Form',{staticClass:"search",attrs:{"label-colon":true,"inline":true}},[_c('FormItem',[_c('LiefengCascaderCity',{attrs:{"resetNum":_vm.resetNum,"orgCode":4401,"width":200,"CascaderList":_vm.cascaderList},on:{"changeCasader":_vm.changeCasader}})],1),_c('FormItem',[_c('Input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"联系电话"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})],1),_c('Button',{attrs:{"type":"info"},on:{"click":function () {
                        _vm.getList()
                    }}},[_vm._v(" 查询 ")]),_c('Button',{attrs:{"type":"default"},on:{"click":function () {
                        _vm.mobile = ''
                        _vm.cascaderList = []
                        _vm.page = 1
                        _vm.resetNum++
                        _vm.getList()
                    }}},[_vm._v(" 重置 ")])],1)]},proxy:true},{key:"contentArea",fn:function(){return [_c('LiefengTable',{attrs:{"talbeColumns":_vm.tableColumns,"tableData":_vm.tableData,"loading":_vm.loading,"fixTable":true,"curPage":_vm.page,"total":_vm.total,"pagesizeOpts":[20, 30, 50, 100],"page-size":_vm.pageSize},on:{"hadlePageSize":_vm.hadlePageSize}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }