<template>
    <LiefengContent :isBack="true"  @backClick="backClick">
        <template v-slot:title>浏览记录</template>
        <template v-slot:toolsbarLeft>
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <LiefengCascaderCity :resetNum="resetNum" @changeCasader="changeCasader" :orgCode="4401" :width="200" :CascaderList="cascaderList" />
                </FormItem>
                <FormItem>
                    <Input v-model="mobile" style="width: 200px" placeholder="联系电话"></Input>
                </FormItem>
                <Button
                    type="info"
                    @click="
                        () => {
                            getList()
                        }
                    "
                >
                    查询
                </Button>
                <Button
                    type="default"
                    @click="
                        () => {
                            mobile = ''
                            cascaderList = []
                            page = 1
                            resetNum++
                            getList()
                        }
                    "
                >
                    重置
                </Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            resetNum:1,
            cascaderList: [],
            mobile: "",
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "姓名",
                    minWidth: 100,
                    key: "userName",
                    align: "center",
                },
                {
                    title: "联系电话",
                    minWidth: 100,
                    key: "mobile",
                    align: "center",
                },
                {
                    title: "浏览时间",
                    minWidth: 100,
                    key: "gmtCreate",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "区",
                    minWidth: 100,
                    key: "zone",
                    align: "center",
                },
                {
                    title: "街镇",
                    minWidth: 100,
                    key: "street",
                    align: "center",
                },
                {
                    title: "社区",
                    minWidth: 100,
                    key: "community",
                    align: "center",
                },
            ],
        }
    },
    methods: {
        backClick(){
            this.$router.push(`/taskpackge`)
        },
        changeCasader(val) {
            this.cascaderList = val
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            if (this.cascaderList && this.cascaderList.length > 1 && this.cascaderList.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
                return
            }
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/question/listTrailActionByPage", {
                dataScopeId: this.cascaderList && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                mobile: this.mobile || "",
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                if (res.dataList && res.dataList.length) {
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                            trailImages: item.trailImages && item.trailImages != "" ? item.trailImages.split(",") : "",
                        }
                    })
                    this.total = res.maxCount
                } else {
                    this.tableData = []
                }
            })
        },
    },
    created() {
        this.getList()
    },
}
</script>

<style lang='less'>
</style>
